import React from 'react';
import styled from 'styled-components'
import UserInfo from './demo/UserInfo'

const Wrapper = styled.div`
  font-size: 13px;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle at 55% 44%, rgb(217, 238, 253), rgb(255, 255, 255) 91%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

function App() {
  return (
    <Wrapper>
      <UserInfo />
    </Wrapper>
  );
}

export default App;
