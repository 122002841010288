import React, {useState, useEffect} from "react"
import styled from "styled-components"
import * as fcl from "@onflow/fcl"

import Card from '../components/Card'
import Button from '../components/Button'
import Input from '../components/Input';
import list from '../list';
import { emailValidators } from '../emailCheck';
import logoFull from '../assets/logo-blocto-swap-full.svg'
import logoFlow from '../assets/flow-logo.svg'
import { getAccountSettings } from '../services/Blocto'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;

  ul, ol {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    padding-inline-start: 20px;
  }
`;

const Header = styled.div`
  flex: 0 0 80px;
  height: 80px;
  width: 100%;
  box-shadow: 0 1px 0 0 rgba(127, 127, 127, 0.1);
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 26px;
  font-size: 15px;
  font-weight: 600;
  box-sizing: border-box;
`;

const Body = styled.div`
  padding: 15px;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`

`;

const Seperator = styled.div`
  width: 1px;
  height: 12px;
  margin: 8px 18px;
  background-color: #c0c0c0;
`;

const Title = styled.div`
  height: 60px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #efefef;
`;

const CardBody = styled.div`
  padding: 30px;
`;

const Content = styled.div`
  font-size: 15px;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.57;

  p {
    margin-block-start: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

const AccountControl = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const FlowLogo = styled.img`
  width: 40px;
  height: 40px;
`;

const WalletName = styled.div`
  width: initial;
  font-size: 12px;
  color: #333333;
  text-transform: uppercase;
`;

const WalletAddress = styled.a`
  color: #131313;
  font-weight: 500;
  font-size: 1rem;
`;

const ActionButton = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: rgb(42, 74, 197);
  color: white;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 20px;
  flex: 0 0 auto;
  font-weight: 600;
  width: fit-content;
  height: fit-content;
  pointer-events: ${props => props.isDisabled ? 'none' : 'inherit'};
  opacity: ${props => props.isDisabled || props.isProcessing ? 0.5 : 1};

  &:hover {
    background-color: rgb(38, 66, 176);
  }
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;

  ${ActionButton} {
    margin-top: 0!important;
    margin-left: 8px;
    padding: 10.5px 10px!important;
  }
`;

const ContentQualified = styled(Content)`
  border-radius: 15px;
  background-color: #f6f6f6;
  padding: 18px;
  margin-bottom: 20px;

  a {
    color: #333333;
  }

  li:not(:last-child) {
    margin-bottom: 8px;
  }

  ${ActionButton} {
    border-radius: 8px;
    margin-top: 8px;
    padding: 8px 16px;
    letter-spacing: 1px;
  }
`;

const ContentNotQualified = styled(Content)`
  border-radius: 15px;
  background-color: rgba(217, 9, 9, 0.1);
  color: #d90909;
  padding: 18px;
  text-align: center;
`;

const PassedLi = styled.li`
  opacity: 0.5;
`;


const UserInfo = () => {
  const [user, setUser] = useState(null)
  const [isQualified, setQualified] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const [defaultEmail, setDefaultEmail] = useState('')
  const [configuredEmail, setConfiguredEmail] = useState('')
  // const [email, setEmail] = useState('')
  // const [error, setError] = useState(false)
  // const [isProcessing, setProcessing] = useState(false);

  const messageProcessor = (event) => {
    try {
      if (event.data.type === "FCL::BLOCTO::INTERNAL") {
        setAccessToken(event.data.accessToken);
        setDefaultEmail(event.data.email);
      }
    } catch (error) {
      console.error("Frame Callback Error", error)
    }
  }

  // const handleError = ({ hasError }) => {
  //   setError(hasError);
  // }

  // const handleEmailChange = (event) => {
  //   setEmail(event.target.value);
  // }

  // const handleSubmit = () => {
  //   if (error || !email) {
  //     return;
  //   }

  //   setProcessing(true)

  //   setAccountSettings({ dapperEmail: email, accessToken })
  //     .subscribe(() => {
  //       setConfiguredEmail(email)
  //       setProcessing(false)
  //     })
  // };

  useEffect(() => {
    fcl.unauthenticate();

    window.addEventListener('message', messageProcessor)

    fcl
      .currentUser()
      .subscribe(user => {
        setUser({...user});
        setQualified(list.find(value => value === user.addr))
      })

    return () => {
      window.removeEventListener('message', messageProcessor);
    }
  }, [])

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    getAccountSettings({ accessToken })
      .subscribe(({ dapper_email }) => {
        setConfiguredEmail(
          dapper_email
            .replace('ceesplug23@me.com', 'ceesplug29@gmail.com')
            .replace('roptoq@hotmail.com', 'ballingshigh@gmail.com')
            .replace('sai.pk1102@gmail.ccom', 'sai.pk1102@gmail.com')
            .replace('77329185@qq.com', 'yunduoer2012@gmail.com')
            .replace('mr.kosmach@mail.ru', 'mr.kosmach@ukr.net')
            .replace('310775779@qq.com', 'wf19920523@gmail.com')
            .replace('sparkcool@qq.com', 'sparkcool@gmail.com')
            .replace('lipan920@126.com', 'lipanpan920@gmail.com')
            .replace('hackrabbit03@gmail.com', 'mr.mowgli.dog@gmail.com')
            .replace('boczeratul@gmail.com', 'aloha@portto.io')
        )
      })
  }, [accessToken]);

  return (
    <Wrapper>
      <Header>
        <Img src={logoFull} alt="logo" />
        <Seperator />
        FLOW token Airdrop
      </Header>
      <Body>
      <Card>
        <Title>FLOW token Airdrop 🪂</Title>

        <CardBody>
          {user && user.addr ? (
            <>
              <InfoCard>
                <FlowLogo src={logoFlow} alt={'flow logo'} />
                <AccountControl>
                  <WalletName>
                    Account Address
                  </WalletName>

                  <WalletAddress>
                    {user.addr}
                  </WalletAddress>
                </AccountControl>
                <ActionButton onClick={fcl.unauthenticate}>Change</ActionButton>
              </InfoCard>

              {isQualified ? (
                <>
                  <ContentQualified>
                    <ol>
                      <PassedLi>
                        You're <b>QUALIFIED</b> for <b>100 locked FLOW</b> airdrop!
                      </PassedLi>
                      <PassedLi>
                        Sign up for a <a href="//accounts.meetdapper.com" target="_blank" rel="noreferrer noopener">Dapper Wallet</a> before <b>March 21st</b>. If you already have a Dapper Wallet, proceed to step 3.
                      </PassedLi>
                      <PassedLi>
                        Enter your Dapper Wallet email. You can only set this <b>ONCE</b>.
                        <InputRow>
                          <Input
                            disabled={true}
                            placeholder={defaultEmail}
                            defaultValue={configuredEmail}
                            validators={emailValidators}
                          />
                          {/* {!Boolean(configuredEmail) && <ActionButton
                            onClick={handleSubmit}
                            isDisabled={Boolean(error) || !Boolean(email)}
                            isProcessing={isProcessing}
                          >
                            Confirm
                          </ActionButton>} */}
                        </InputRow>
                      </PassedLi>
                      <li>Wait for KYC email after <b>March 22nd</b>.</li>
                    </ol>
                  </ContentQualified>
                  <Content>
                    Note that you <b>MUST</b> complete all the steps above to receive the airdrop. If you fail to meet any of the deadlines above, you will be <b>DISQUALIFIED</b> from the FLOW token drop.
                    <ul>
                      <li>
                        It's preferred that you use the same email across Dapper Wallet and Blocto.
                      </li>
                      <li>
                        If you do not provide a mapping, we assume you have <b>EXACT SAME EMAIL</b> between Dapper Wallet and Blocto.
                      </li>
                      <li>
                        You <b>CANNOT</b> reuse the same Dapper account on multiple Blocto accounts.
                      </li>
                      <li>You will have <b>1 week</b> upon receiving the KYC email to complete the process and submit all required info and documents.</li>
                    </ul>
                  </Content>
                </>
              ) : (
                <ContentNotQualified>
                  <p>
                    You're <b>NOT QUALIFIED</b> for the airdrop.
                  </p>
                  <p>
                    Please check you have entered the correct email. UPPERCASE and lowercase are considered different in Blocto email IDs.
                  </p>
                </ContentNotQualified>
              )}
            </>
          ) : (
            <>
              <Content>
                <p>
                  As a way to show our gratitude to the users who participated in the BloctoSwap launch. We are sending out <b>100 locked FLOW tokens</b> to your Blocto Flow wallet, if you <b>teleported USDT to tUSDT before launch time (Jan 23rd)</b>.
                </p>

                <p>
                  Please follow the instructions to confirm your qualification.
                </p>
              </Content>
              <Button onClick={fcl.authenticate}>Connect Wallet</Button>
            </>
          )}
        </CardBody>
      </Card>
      </Body>
    </Wrapper>
  )
}

export default UserInfo
